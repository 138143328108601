import parse from "html-react-parser"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ImageContainer from "../../features/image-container"
import Slide from "../../utility/slide_content"
import Buttons from "../button"
import "./Block.scss"

const Block = props => {
  return (
    <>
      <div className="Block" id={props.scrollId && `#${props.scrollId}`}>
        <Slide delay="200">
          <Container className="Layout-container">
            <Row className="lr-parent">
              <Col
                lg={props.lgLeft ? props.lgLeft : 5}
                className={`left ${props.className}`}
              >
                {props.html ? (
                  <>{parse(props.html)}</>
                ) : (
                  <>
                    {props?.tag && (
                      <div className="tag">
                        <p>{props?.tag}</p>
                      </div>
                    )}
                    <div className="heading">
                      <h2>{props?.heading}</h2>
                    </div>

                    <div className="description">
                      <p>{props?.description}</p>
                    </div>

                    {props.primaryButtonText && (
                      <div className="buttons">
                        <Buttons
                          theme="primary"
                          arrow
                          link={props.primaryButtonLink}
                          text={props.primaryButtonText}
                        />

                        {props.secondaryButtonText && (
                          <Buttons
                            theme="secondary"
                            arrow
                            link={props.secondaryButtonLink}
                            text={props.secondaryButtonText}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </Col>
              <Col lg={props.lgRight ? props.lgRight : 6} className="right">
                <ImageContainer
                  blockImageTitle={props.imageTitle}
                  blockImage={props.image}
                  blockIsIllustration={props.isImage}
                  blockIsVideo={props.isVideo}
                  decoration={props.decoration}
                />
              </Col>
            </Row>
          </Container>
        </Slide>
      </div>
    </>
  )
}

export default Block
